<template>
  <div v-if="state.isInitialLoading" class="p-4 bg-white flex items-center rounded-md shadow-sm">
    <loading class="text-big-stone w-5 h-5 mr-3" />
    Loading notes...
  </div>
  <template v-else>
    <form-layout
      @submit="save"
      title="Machine Notes"
      icon="note"
    >
      <div class="space-y-2">
        <errors :errors="state.errors" />
        <textarea
          v-model="state.message"
          class="w-full bg-gray-50 rounded border-gray-200 text-gray-800"
          rows="5"
        />

        <div class="text-right">
          <submit-button :is-loading="state.isSaving">Save</submit-button>
        </div>
      </div>
    </form-layout>
  </template>
</template>

<script>
import { reactive, watch } from 'vue'

import http from '@/services/http.js'
import notification from '@/services/notifications.js'

import Loading from '@/components/icons/Loading.vue'
import FormLayout from '@/components/form/Layout.vue'
import Errors from '@/components/form/Errors.vue'
import TextInput from '@/components/form/Text.vue'
import SubmitButton from '@/components/form/SubmitButton.vue'


export default {
  name: 'machine-notes',

  components: {
    FormLayout,
    TextInput,
    SubmitButton,
    Errors,
    Loading,
  },

  props: ['machineId'],

  setup(props) {
    const state = reactive({
      errors: [],
      isInitialLoading: true,
      isSaving: false,
      message: '',
    })

    const save = async () => {
      state.errors = []
      state.isSaving = true

      try {
        await http.post(
          `machine/${props.machineId}/admincomment`,
          {
            message: state.message
          }
        )
        notification.success('Notes saved')
      } catch (e) {
        if (e.response) {
          state.errors.push(e.response.data.Message)
        } else {
          state.errors.push('Something went wrong. Please, try again.')
        }
      }

      state.isSaving = false
    }

    const getNotes = async () => {
      if (!props.machineId) {
        return
      }
      state.isInitialLoading = true
      try {
        const { data } = await http.get(`machine/${props.machineId}/admincomment`)
        state.message = data.message
      } catch (e) {
        notification.error('Error loading notes')
      }
      state.isInitialLoading = false
    }

    getNotes()
    watch(
      () => props.machineId,
      () => {
        getNotes()
      }
    )

    return {
      state,
      save,
    }
  }
}
</script>
