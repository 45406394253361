<template>
  <group :title="title">
    <repeater-group @add="() => model.push(null)">
      <repeater v-for="(value, index) in model" :key="index" :index="index" @remove="(index) => model.splice(index, 1)">
        <text-input label="Value" v-model="model[index]" />
      </repeater>
    </repeater-group>
  </group>
</template>

<script>
import Group from '@/views/settings/machine-profile/Group'
import RepeaterGroup from '@/views/settings/machine-profile/RepeaterGroup'
import Repeater from '@/views/settings/machine-profile/Repeater'
import TextInput from '@/components/form/Text.vue'
import { ref, watch } from 'vue'

export default {
  name: 'IceVend',

  components: {
    Repeater,
    RepeaterGroup,
    Group,
    TextInput,
  },

  props: ['modelValue', 'title'],

  setup(props, { emit }) {
    const model = ref(props.modelValue)

    watch(
      () => model,
      (v) => emit('update:modelValue', v)
    )

    return {
      model,
    }
  }
}
</script>

<style scoped>

</style>
