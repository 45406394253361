<template>
  <div class="pt-px">
    <div class="p-4 rounded-lg border relative bg-gray-50 bg-opacity-25 mt-4">
      <h3 class="font-bold absolute top-0 left-0 -mt-3 bg-white ml-4 px-1">{{ title }}</h3>
      <button type="button" class="font-bold absolute top-0 right-0 -mt-2.5 bg-white mr-4 px-1 text-sm uppercase" @click="isVisible = ! isVisible">
        <span v-if="isVisible">Hide</span>
        <span v-else>Show</span>
      </button>

      <div v-if="isVisible" class="mt-2 space-y-2">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'MachineProfileGroup',

  props: ['title'],

  setup() {
    const isVisible = ref(false)

    return {
      isVisible,
    }
  }
}
</script>
