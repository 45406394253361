<template>
  <div class="border rounded-lg p-2 flex space-x-2 relative bg-white">
    <button type="button" @click="$emit('remove', index)" class="absolute top-0 right-0 text-red-600 mr-2 mt-px">
      <span class="material-icons-outlined text-lg">close</span>
    </button>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Repeater',

  props: ['index'],

  emits: ['remove']
}
</script>
