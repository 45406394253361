<template>
  <group :title="title">
    <repeater-group title="" @add="() => model.push({})">
      <repeater v-for="(register, index) in model" :key="index" :index="index" @remove="(index) => model.splice(index, 1)">
        <text-input label="io_type" v-model="model[index].io_type" />
        <text-input label="label" v-model="model[index].label" />
        <text-input label="register" v-model="model[index].register" />
      </repeater>
    </repeater-group>
  </group>
</template>

<script>
import Group from '@/views/settings/machine-profile/Group'
import RepeaterGroup from '@/views/settings/machine-profile/RepeaterGroup'
import Repeater from '@/views/settings/machine-profile/Repeater'
import TextInput from '@/components/form/Text.vue'
import { ref, watch } from 'vue'

export default {
  name: 'DisplayRegisters',

  components: {
    Repeater,
    RepeaterGroup,
    Group,
    TextInput,
  },

  props: ['modelValue', 'title'],

  setup(props, { emit }) {
    const model = ref(props.modelValue)

    watch(
      () => model,
      (v) => emit('update:modelValue', v)
    )

    return {
      model,
    }
  }
}
</script>

<style scoped>

</style>
