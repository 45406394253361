<template>
  <layout>
    <template #secondarySidebar>
      <settings-nav />
    </template>

    <alert-response-warning v-if="! state.hasAccess" :error="state.accessError" />

    <div v-else >
      <div class="flex flex-row justify-between items-center border-b pb-2">
        <h2 class="font-bold text-lg sm:text-2xl">{{ state.machineName }}</h2>

        <router-link :to="{ name: 'SettingsMachinesIndex'}" class="group">
          <div class="flex items-center">
            <svg class="w-4 h-4 text-gray-400 group-hover:text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
            <p class="text-sm text-gray-500 group-hover:text-gray-900">Back</p>
          </div>
        </router-link>
      </div>

      <div class="space-y-6 xl:space-y-8 mt-6 xl:mt-8">        
        <machine-notes :machine-id="state.machineId" />

        <form-layout
          v-if="state.isJsonEditor"
          :is-loading="state.isInitialLoading"
          title="JSON Editor"
          icon="code"
        >
          <json-editor :json="JSON.stringify(state.form, null, 2)" @apply="applyJson" @discard="state.isJsonEditor = false" />
        </form-layout>

        <form-layout
          v-else
          :is-loading="state.isInitialLoading"
          :title="`Update ${state.machineName} (${state.machineId}) Profile`"
          icon="perm_data_setting"
          @submit="update"
        >
          <div class="mb-4 md:mb-8">
            <button type="button" @click="state.isJsonEditor = true" class="inline-flex justify-center items-center py-2 px-4 border border-transparent text-sm md:text-base rounded-md text-white bg-big-stone hover:bg-blumine focus:outline-none focus:ring-2 focus:ring-sail focus:shadow-outline-sail active:bg-blumine transition duration-150 ease-in-out">
              Switch to JSON Editor
            </button>
          </div>

          <div class="space-y-4 md:space-y-6" v-if="! state.isInitialLoading">
            <errors :errors="state.errors" />

            <group title="Ice Products">
              <text-input label="product1" v-model="state.form.product1" />
              <text-input label="product2" v-model="state.form.product2" />
              <text-input label="product3" v-model="state.form.product3" />
            </group>

            <ice-vend title="Register Ice Vend Only On" v-model="state.form.register_ice_vend_only_on" />

            <water-products title="Water Products" v-model="state.form.water_products" />

            <water-vend title="Register Water Vend Only On" v-model="state.form.register_water_vend_only_on" />

            <bill-validator title="Bill Validator 1" v-model="state.form.bill_validator_1" />
            <bill-validator title="Bill Validator 2" v-model="state.form.bill_validator_2" />
            <coin-changer label="Coin Changer" v-model="state.form.coin_changer" />
            <recycler title="Recycler" v-model="state.form.recycler" />

            <group title="Sensors">
              <text-input label="hold_free_indicator" v-model="state.form.hold_free_indicator" />
              <text-input label="double_bagger" v-model="state.form.double_bagger" />
              <text-input label="bag_failure" v-model="state.form.bag_failure" />
              <text-input label="bag_hopper_fail_latch" v-model="state.form.bag_hopper_fail_latch" />
              <text-input label="bag_hopper_piston" v-model="state.form.bag_hopper_piston" />
              <text-input label="bag_hopper_sensor" v-model="state.form.bag_hopper_sensor" />
              <text-input label="bag_open_fail_latch" v-model="state.form.bag_open_fail_latch" />
              <text-input label="bulk_failure" v-model="state.form.bulk_failure" />
              <text-input label="bulk_hopper_piston" v-model="state.form.bulk_hopper_piston" />
              <text-input label="bulk_hopper_sensor" v-model="state.form.bulk_hopper_sensor" />
              <text-input label="bulk_only" v-model="state.form.bulk_only" />
              <text-input label="bulk_only_msg_latch" v-model="state.form.bulk_only_msg_latch" />
              <text-input label="door_sensor" v-model="state.form.door_sensor" />
              <text-input label="door_sensor_open_value" v-model="state.form.door_sensor_open_value" />
              <text-input label="harvest_im1" v-model="state.form.harvest_im1" />
              <text-input label="harvest_im2" v-model="state.form.harvest_im2" />
              <text-input label="im1_on_off" v-model="state.form.im1_on_off" />
              <text-input label="im2_on_off" v-model="state.form.im2_on_off" />
              <text-input label="bin_full_1" v-model="state.form.bin_full_1" />
              <text-input label="bin_full_2" v-model="state.form.bin_full_2" />
              <text-input label="compressor_running_1" v-model="state.form.compressor_running_1" />
              <text-input label="compressor_running_2" v-model="state.form.compressor_running_2" />
              <text-input label="hopper_stuck_latch" v-model="state.form.hopper_stuck_latch" />
              <text-input label="incline_auger_jammed" v-model="state.form.incline_auger_jammed" />
              <text-input label="incline_auger_motor" v-model="state.form.incline_auger_motor" />
              <text-input label="incline_rake_bin_motor_overload" v-model="state.form.incline_rake_bin_motor_overload" />
              <text-input label="no_ice" v-model="state.form.no_ice" />
              <text-input label="out_of_service" v-model="state.form.out_of_service" />
              <text-input label="overload" v-model="state.form.overload" />
              <text-input label="plc_fault" v-model="state.form.plc_fault" />
              <text-input label="shock_sensor" v-model="state.form.shock_sensor" />
              <text-input label="air_pressure_sensor" v-model="state.form.air_pressure_sensor" />
              <text-input label="teeter_totter" v-model="state.form.teeter_totter" />
              <text-input label="token" v-model="state.form.token" />
              <text-input label="trap_door_open" v-model="state.form.trap_door_open" />
              <text-input label="vending" v-model="state.form.vending" />
              <text-input label="bin_floor_motor" v-model="state.form.bin_floor_motor" />
              <text-input label="blocker" v-model="state.form.blocker" />
              <text-input label="rake_failure" v-model="state.form.rake_failure" />
              <text-input label="rake_motor" v-model="state.form.rake_motor" />
              <text-input label="blower" v-model="state.form.blower" />
              <text-input label="blower_overload" v-model="state.form.blower_overload" />
              <text-input label="reset_mdb_pro" v-model="state.form.reset_mdb_pro" />
              <text-input label="leveler_overload" v-model="state.form.leveler_overload" />
              <text-input label="im1_fault" v-model="state.form.im1_fault" />
              <text-input label="im2_fault" v-model="state.form.im2_fault" />
              <text-input label="water_vendor_1_fault" v-model="state.form.water_vendor_1" />
              <text-input label="water_vendor_2_fault" v-model="state.form.water_vendor_2" />
            </group>

            <group title="Ladder logic steps">
              <text-input label="step_wait_for_credit" v-model="state.form.step_wait_for_credit" />
              <text-input label="step_wait_for_selection" v-model="state.form.step_wait_for_selection" />
              <text-input label="step_vend_bag" v-model="state.form.step_vend_bag" />
              <text-input label="step_vend_bulk" v-model="state.form.step_vend_bulk" />
              <text-input label="step_vend_small_bag" v-model="state.form.step_vend_small_bag" />
            </group>

            <machine-buttons title="Buttons" v-model="state.form.buttons" />

            <group title="Cash Out">
              <text-input label="cashout_bills" v-model="state.form.cashout_bills" />
              <text-input label="cashout_cc" v-model="state.form.cashout_cc" />
              <text-input label="cashout_coins" v-model="state.form.cashout_coins" />
              <text-input label="cashout_sales" v-model="state.form.cashout_sales" />
              <text-input label="cashout_token" v-model="state.form.cashout_token" />
              <text-input label="cashout_vends" v-model="state.form.cashout_vends" />
            </group>

            <group title="Credit">
              <text-input label="credit" v-model="state.form.credit" />
              <text-input label="water_credit" v-model="state.form.water_credit" />
              <text-input label="credit_after_decimal" v-model="state.form.credit_after_decimal" />
              <text-input label="credit_before_decimal" v-model="state.form.credit_before_decimal" />
              <text-input label="credit_card" v-model="state.form.credit_card" />
              <text-input label="credit_card_reader_installed" v-model="state.form.credit_card_reader_installed" />
            </group>

            <price-lines title="Price Lines" v-model="state.form.price_lines" />

            <group title="Display Readings">
              <text-input label="ice_weight" v-model="state.form.ice_weight" />
              <text-input label="tare_weight" v-model="state.form.tare_weight" />
              <text-input label="temp" v-model="state.form.temp" />
              <text-input label="cutoff_weight" v-model="state.form.cutoff_weight" />
              <text-input label="icemaker_error" v-model="state.form.icemaker_error" />
            </group>

            <display-registers title="Display I/O Registers" v-model="state.form.display_registers" />
          </div>
          <template #actions>
            <submit-button :is-loading="state.isSaving">Save</submit-button>
          </template>
        </form-layout>
      </div>
    </div>
  </layout>
</template>

<script>
import { reactive } from 'vue'
import { useRoute } from 'vue-router'
import merge from 'lodash/merge'
import http from '@/services/http.js'
import notification from '@/services/notifications.js'
import Layout from '@/layouts/Default.vue'
import FormLayout from '@/components/form/Layout.vue'
import Errors from '@/components/form/Errors.vue'
import TextInput from '@/components/form/Text.vue'
import AlertResponseWarning from '@/components/alerts/ResponseWarning.vue'
import SubmitButton from '@/components/form/SubmitButton.vue'
import JsonEditor from '@/views/settings/machine-profile/JsonEditor'
import Group from '@/views/settings/machine-profile/Group'
import BillValidator from '@/views/settings/machine-profile/BillValidator'
import CoinChanger from '@/views/settings/machine-profile/CoinChanger'
import PriceLines from '@/views/settings/machine-profile/PriceLines'
import Recycler from '@/views/settings/machine-profile/Recycler'
import MachineButtons from '@/views/settings/machine-profile/MachineButtons'
import DisplayRegisters from '@/views/settings/machine-profile/DisplayRegisters'
import IceVend from '@/views/settings/machine-profile/IceVend'
import WaterVend from '@/views/settings/machine-profile/WaterVend'
import WaterProducts from '@/views/settings/machine-profile/WaterProducts'
import SettingsNav from '@/components/settings/Nav'
import MachineNotes from '@/components/machines/MachineNotes'

export default {
  name: 'SettingsMachinesProfileEdit',

  components: {
    WaterProducts,
    WaterVend,
    IceVend,
    DisplayRegisters,
    MachineButtons,
    Recycler,
    PriceLines,
    CoinChanger,
    BillValidator,
    JsonEditor,
    Layout,
    AlertResponseWarning,
    FormLayout,
    TextInput,
    SubmitButton,
    Errors,
    Group,
    SettingsNav,
    MachineNotes,
  },

  setup() {
    const route = useRoute()
    const machineId = route.params.machineId

    const state = reactive({
      hasAccess: true,
      accessError: null,
      errors: [],
      isInitialLoading: false,
      isSaving: false,
      form: {},
      json: '',
      isJsonEditor: false,
      machineName: null,
      machineId: null,
    })

    const getProfile = async () => {
      state.isInitialLoading = true

      try {
        const { data: machineData } = await http.get(`/machine/${machineId}`)
        state.machineName = machineData.model.name
        state.machineId = machineData.model.location_id

        const { data } = await http.get(`/machine/${machineId}/profile`)
        state.form = data
      } catch (e) {
        state.hasAccess = false
        state.accessError = e
      }

      state.isInitialLoading = false
    }

    const update = async () => {

      state.errors = []
      state.isSaving = true

      const notif = notification.async('Saving...')

      try {
        const { data } = await http.post(`/machine/${machineId}/profile`, state.form)
        merge(state.form, data)
        notif.resolve('success', 'Success', 'Machine Profile has been updated')
      } catch (e) {
        if (e.response) {
          state.errors.push(e.response.data.Message)
          notif.resolve('error', 'Error', e.response.data.Message)
        } else {
          notif.resolve('error', 'Error', 'Something went wrong. Please, try again.')
        }
      }

      state.isSaving = false
    }

    const applyJson = (json) => {
      try {
        state.form = JSON.parse(json)
        state.isJsonEditor = false
      } catch (e) {
        notification.error('JSON has incorrect format')
      }
    }

    getProfile()

    return {
      state,
      update,
      applyJson,
    }
  }
}
</script>
