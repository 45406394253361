<template>
  <group :title="title">
    <repeater-group title="Bill Types" @add="() => model.currency_types.push(null)">
      <repeater v-for="(type, index) in model.currency_types" :key="index" :index="index" @remove="(index) => model.currency_types.splice(index, 1)">
        <text-input label="Type Register" v-model="model.currency_types[index].type_register" />
        <text-input label="full_or_jam_register" v-model="model.currency_types[index].full_or_jam_register" />
        <text-input label="count_register" v-model="model.currency_types[index].count_register" />
      </repeater>
    </repeater-group>
    <text-input label="present" v-model="model.present" />
    <text-input label="scaling_factor" v-model="model.scaling_factor" />
    <text-input label="status" v-model="model.status" />
  </group>
</template>

<script>
import Group from '@/views/settings/machine-profile/Group'
import RepeaterGroup from '@/views/settings/machine-profile/RepeaterGroup'
import Repeater from '@/views/settings/machine-profile/Repeater'
import TextInput from '@/components/form/Text.vue'
import { ref, watch } from 'vue'

export default {
  name: 'Recycler',

  components: {
    Repeater,
    RepeaterGroup,
    Group,
    TextInput,
  },

  props: ['modelValue', 'title'],

  setup(props, { emit }) {
    const model = ref(props.modelValue)

    watch(
      () => model,
      (v) => emit('update:modelValue', v)
    )

    return {
      model,
    }
  }
}
</script>

<style scoped>

</style>
