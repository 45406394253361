<template>
  <div class="shadow rounded-lg p-4 relative bg-gray-50">
    <h4 class="font-bold">{{ title }}</h4>
    <div class="space-y-2 mt-4">
      <slot />
    </div>

    <div class="text-center">
      <button type="button" @click="$emit('add')" class="font-bold text-sm">ADD</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RepeaterGroup',

  props: ['title'],

  emits: ['add']
}
</script>
