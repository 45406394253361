<template>
  <label class="sr-only" for="json-editor">JSON EDITOR</label>
  <textarea id="json-editor" class="w-full bg-gray-50 rounded border-gray-200 text-gray-800" rows="40" v-model="jsonModel" />

  <div class="flex justify-between">
    <button @click="discard" class="inline-flex justify-center items-center py-2 px-4 border border-transparent rounded-md text-big=stone font-semibold bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:shadow-outline-gray-200 active:bg-gray-500 transition duration-150 ease-in-out">Discard</button>
    <submit-button type="button" @click="apply">Apply</submit-button>
  </div>
</template>

<script>
import { ref } from 'vue'
import SubmitButton from '@/components/form/SubmitButton'

export default {
  name: 'JsonEditor',

  components: {
    SubmitButton
  },

  props: ['json'],

  emits: ['apply', 'discard'],

  setup(props, { emit }) {
    const jsonModel = ref(props.json)

    const apply = () => {
      emit('apply', jsonModel.value)
    }

    const discard = () => {
      emit('discard')
    }

    return {
      jsonModel,
      apply,
      discard,
    }
  }
}
</script>
