<template>
  <group :title="title">
    <repeater-group title="Buttons" @add="() => model.push({})">
      <repeater v-for="(button, index) in model" :key="index" :index="index" @remove="(index) => model.splice(index, 1)">
        <text-input label="Name" v-model="model[index].name" readonly />
        <selecbox label="Color" v-model="model[index].color">
          <option v-for="(label, key) in colorOptions" :key="key" :value="key">{{ label }}</option>
        </selecbox>
        <text-input label="Label" v-model="model[index].label" maxlength="50" />
        <div class="flex flex-col mt-1">
          <v-label :forEl="`buttons-enabled-${index}`">Enabled</v-label>
          <toggle :name="`buttons-enabled-${index}`" v-model="model[index].enabled" class="mt-1" />
        </div>
      </repeater>
    </repeater-group>
  </group>
</template>

<script>
import Group from '@/views/settings/machine-profile/Group'
import RepeaterGroup from '@/views/settings/machine-profile/RepeaterGroup'
import Repeater from '@/views/settings/machine-profile/Repeater'
import TextInput from '@/components/form/Text.vue'
import { computed, ref, watch } from 'vue'
import Toggle from '@/components/form/Toggle'
import VLabel from '@/components/form/Label'
import Selecbox from '@/components/form/Select'

export default {
  name: 'MachineButtons',

  components: {
    Selecbox,
    VLabel,
    Toggle,
    Repeater,
    RepeaterGroup,
    Group,
    TextInput,
  },

  props: ['modelValue', 'title'],

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const model = ref(props.modelValue)

    const colorOptions = computed(() => ({
      red: 'Red',
      yellow: 'Yellow',
      green: 'Green',
      default: 'Default',
    }))

    watch(
      () => model,
      (v) => emit('update:modelValue', v)
    )

    return {
      model,
      colorOptions,
    }
  }
}
</script>

<style scoped>

</style>
